.Theme_shadow_defaultLTLTheme {
  /* Тени */
  --shadow-group:
    0 2px 2px var(--color-shadow-group-1),
    0 2px 8px var(--color-shadow-group-2);
  --shadow-layer:
    0 4px 4px var(--color-shadow-layer-1),
    0 8px 24px var(--color-shadow-layer-2);
  --shadow-modal:
    0 8px 8px var(--color-shadow-modal-1),
    0 12px 28px var(--color-shadow-modal-2);
}
