@font-face {
  font-family: 'Roboto Flex';
  src: url('RobotoFlex-VariableFont.ttf');
}

.Theme_font_defaultLTLTheme {
  --font-primary:
    'Roboto Flex',
    -apple-system,
    BlinkMacSystemFont,
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  --font-mono:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
  font-family: var(--font-primary);
}
