.Theme_color_defaultLTLTheme {
	/* ////////////////
	// Цвета фонов / */
	--color-bg-default: #151515; /* Цвет дефолта */
	--color-bg-secondary: #080808; /* Цвет дефолта */
	--color-bg-brand: #FFEF5C; /* Цвет фона брендированый */
	--color-bg-link: #FFEC3D; /* Цвет фона ссылки */
	--color-bg-border: rgba(242, 249, 252, 0.2); /* Цвет границ */
	--color-bg-stripe: rgba(200, 231, 243, 0.05); /* Цвет зебры */
	--color-bg-ghost: rgba(200, 231, 243, 0.08); /* Цвет затенения */
	--color-bg-tone: rgba(21, 21, 21, 0.85); /* Цвет паранджи */
	--color-bg-soft: rgba(255, 255, 255, 0.9); /* Цвет молока */
	--color-bg-system: #4D525B; /* Цвет фона системнный */
	--color-bg-normal: #FFEB3B; /* Цвет фона нейтральный */
	--color-bg-success: #22C38E; /* Цвет фона успеха */
	--color-bg-caution: #F2C94C; /* Цвет фона осторожности */
	--color-bg-warning: #F38B00; /* Цвет фона предупреждения */
	--color-bg-alert: #FF3838; /* Цвет фона ошибки */
	--color-bg-critical: #33000F; /* Цвет фона системнный */
	--color-bg-shadow: rgba(0, 0, 0, 0.4); /* Цвет тени */



	/* /////////////////
	// Цвета текста / */
	--color-typo-primary: #FAFAFA; /* Цвет основного текста */
	--color-typo-secondary: rgba(255, 255, 255, 0.6); /* Цвет второстепенного текста */
	--color-typo-ghost: rgba(255, 255, 255, 0.3); /* Цвет дополнительного текста */
	--color-typo-brand: #FFEF5C; /* Цвет брендового текста */
	--color-typo-system: #636E83; /* Цвет текста системнный */
	--color-typo-normal: #FFE927; /* Цвет текста нейтральный */
	--color-typo-success: #0FAD79; /* Цвет текста успеха */
	--color-typo-caution: #F8C837; /* Цвет текста осторожности */
	--color-typo-warning: #E48200; /* Цвет текста предупреждения */
	--color-typo-alert: #FB2D2D; /* Цвет текста ошибки */
	--color-typo-critical: #61001D; /* Цвет текста системнный */
	--color-typo-link: #FFEE7A; /* Цвет основной ссылки */
	--color-typo-link-minor: #E6E0BC; /* Цвет второстепенной ссылки */
	--color-typo-link-hover: #FFF7C2; /* Цвет ховера ссылки */
	--color-type-disabled: rgba(255, 255, 255, 0.64); /* Цвет текста неактивный */


	/* ////////////////////
	// Цвета контролов / */

	/* Default */
	--color-control-bg-default: #151515; /* Цвет фона контрола */
	--color-control-typo-default: rgba(255, 255, 255, 0.8); /* Цвет основного текста контрола */
	--color-control-typo-placeholder: rgba(255, 255, 255, 0.35); /* Цвет плейсхолдера контрола */
	--color-control-bg-border-default: rgba(246, 251, 253, 0.28); /* Цвет фона бордеров котрола */
	--color-control-bg-border-default-hover: rgba(246, 251, 253, 0.52); /* Цвет фона бордеров котрола по ховеру */
	--color-control-bg-border-focus: rgba(246, 251, 253, 0.52); /* Цвет аутлайна контролов при фокусе */
	--color-control-bg-focus: rgba(255, 236, 61, 0.4); /* Цвет тени в состонии фокуса */
	--color-control-bg-active: rgba(255, 236, 61, 0.7); /* Цвет тени в состонии нажатия */

	/* Primary */
	--color-control-bg-primary: #FFEC3D; /* Цвет фона активного контрола */
	--color-control-bg-primary-hover: #FFF16B; /* Цвет фона активного контрола по ховеру */
	--color-control-typo-primary: #FFFFFF; /* Цвет акцентного текста */
	--color-control-typo-primary-hover: #FFFFFF; /* Цвет акцентного текста */

	/* Secondary */
	--color-control-bg-secondary: rgba(21, 21, 21, 0); /* Цвет фона бордеров контрола */
	--color-control-bg-border-secondary: #FFEC3D; /* Цвет фона бордеров котрола */
	--color-control-bg-border-secondary-hover: #FFF16B; /* Цвет фона бордеров контрола по ховеру */
	--color-control-typo-secondary: #FFEE7A; /* Цвет второстепеного текста контрола */
	--color-control-typo-secondary-hover: #FFF7C2; /* Цвет акцентного текста */

	/* Ghost */
	--color-control-bg-ghost: rgba(250, 250, 250, 0.32); /* Цвет фона дополнительного контрола */
	--color-control-bg-ghost-hover: rgba(250, 250, 250, 0.23); /* Цвет фона дополнительного контрола по ховеру */
	--color-control-typo-ghost: rgba(255, 255, 255, 0.75); /* Цвет дополнительного текста контрола */
	--color-control-typo-ghost-hover: rgba(255, 255, 255, 0.9); /* Цвет дополнительного текста контрола по ховеру */

	/* Clear */
	--color-control-bg-clear: rgba(250, 250, 250, 0); /* Цвет фона чистого контрола */
	--color-control-bg-clear-hover: rgba(250, 250, 250, 0.23); /* Цвет фона чистого контрола по ховеру */
	--color-control-typo-clear: rgba(255, 255, 255, 0.75); /* Цвет акцентного текста */
	--color-control-typo-clear-hover: rgba(255, 255, 255, 0.9); /* Цвет акцентного текста */

	/* Disable */
	--color-control-bg-disable: rgba(250, 250, 250, 0.16); /* Цвет фона неактивного контрола */
	--color-control-bg-border-disable: rgba(250, 250, 250, 0); /* Цвет фона бордеров неактивного контрола */
	--color-control-typo-disable: rgba(255, 255, 255, 0.24); /* Цвет основного текста неактивного контрола */


	/* ///////////////
	// Цвета тени / */
	--color-shadow-group-1: rgba(0, 0, 0, 0.08);
	--color-shadow-group-2: rgba(0, 0, 0, 0.32);
	--color-shadow-layer-1: rgba(0, 0, 0, 0.08);
	--color-shadow-layer-2: rgba(0, 0, 0, 0.32);
	--color-shadow-modal-1: rgba(0, 0, 0, 0.08);
	--color-shadow-modal-2: rgba(0, 0, 0, 0.32);
}